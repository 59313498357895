<template>
    <div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-8 col-sm-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-md-0">
                  <!-- Image -->
                  <div class="text-center">
                    <img src="@/assets/boolink/sally2.png" alt="..." class="img-fluid" />
                  </div>
                </div>
                <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5" ref="loginForm">
                  <!-- Heading <h1 class="display-4 text-center mb-3">{{$t('Reset Password')}}</h1>-->

                    <img src="@/assets/source/logo-white.svg" alt="..." class="img-fluid mb-2"/>

                  <!-- Subheading -->
                  <p class="color-white mb-5 mt-2">{{$t('Create new password for your account')}}</p>

                  <!-- Email address -->
                  <form @submit="verifyResetPassword">
                    <div class="form-group">
                      <!-- Label -->
                      <label class="color-white">{{$t('Password')}}</label>

                      <!-- Input -->
                      <password-viewable v-model="data.password" :placeholder="$t('Enter new password')"></password-viewable>
                    </div>

                    <!-- Password -->
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <!-- Label -->
                          <label class="color-white">{{$t('Repeat Password')}}</label>
                        </div>
                      </div>
                      <!-- / .row -->
                      <!-- Input group -->
                      <password-viewable v-model="data.repeatPassword" :placeholder="$t('Repeat password')"></password-viewable>
                    </div>

                    <!-- Submit -->
                    <button-loading
                        class="btn btn-lg btn-block btn-primary mb-3 custom-btn"
                        type="submit"
                        :loading="loading"
                        @click="verifyResetPassword"
                    >{{$t('Create New Password')}}</button-loading>
                  </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PasswordViewable from '@/components/PasswordViewable.vue'
import ButtonLoading from '@/components/ButtonLoading'

export default {
    components: {
      PasswordViewable,
      ButtonLoading
    },
    data() {
        return {
          data: {},
          errors: null,
        };
    },
    computed: {
        ...mapState("authManagement", {
            loading: "isCreatePending"
        }),
    },
    created() {
    },
    methods: {

        ...mapActions("authManagement", {
            verifyPasswordResetToken: "create",
        }),

        verifyResetPassword() {
            let token = this.$route.query.token
            if (!token) return;
            let password = this.data.password

            this.verifyPasswordResetToken({
                action: "resetPwdLong",
                value: {
                  token: token,
                  password
                }
            })
            .then((res) => {
              //console.log(res)
                this.$toasted.global.success({
                    message: this.$t("Success"),
                });
              this.$router.push({ name: 'login' });
            })
            .catch((error) => {
              //console.log(error)
                this.$toasted.global.error({
                    message: this.$t(error.message),
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-auth {
    height: 100%;
    min-height: 100vh;
}
</style>
